import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { TFunction } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SvgDoubleArrows from '../../images/news/doubleArrows.inline.svg';
import { Post, PageContextProps, AllMdxPost, GraphqlPost } from '../../utils/graphql';
import translateLabel from '../../utils/translate-label';
import { SharedCard } from '../Card';
import { Informations } from './post-item';
import * as typesHelper from './typesHelper';

type HearderLinkProps = {
  name: string;
  path: string;
  root?: boolean;
};
const HearderLink: React.VoidFunctionComponent<HearderLinkProps> = ({ name, path, root }) => {
  const targetPath = typesHelper.useTargetPath(path);
  const activeClassName = typesHelper.useIsActivePath(targetPath, root)
    ? 'border-current'
    : 'border-transparent opacity-70';

  return (
    <Link to={targetPath} title={name} className="px-3 py-4 no-underline lg:mr-3 lg:px-0">
      <span className={`border-b-2 pb-2 font-semibold lg:text-2xl xl:text-3xl ${activeClassName}`}>
        {name}
      </span>
    </Link>
  );
};

type NewsHeaderProps = {
  post: Post;
  siteUrl: string;
  pageContext: PageContextProps;
  t: TFunction;
};
const NewsHeader: React.VoidFunctionComponent<NewsHeaderProps> = ({
  post,
  siteUrl,
  pageContext,
  t,
}) => {
  const bgColors = typesHelper.usePreviousAndCurrentAndNextBackgroundColor();
  const informations = Informations(t, post, pageContext.locale);
  const {
    articleImage: { nodes: articleImageNodes },
    podcastImage: { nodes: podcastImageNodes },
    eventImage: { nodes: eventImageNodes },
  }: {
    articleImage: AllMdxPost;
    podcastImage: AllMdxPost;
    eventImage: AllMdxPost;
  } = useStaticQuery(
    graphql`
      query {
        articleImage: allMdx(
          filter: {
            fields: { source: { eq: "news" }, slug: { ne: null } }
            frontmatter: { type: { ne: null, eq: "Article" }, Published: { eq: true } }
          }
          sort: { fields: [fields___date, fields___slug, fields___locale], order: DESC }
          limit: 1
        ) {
          nodes {
            fields {
              socialImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        podcastImage: allMdx(
          filter: {
            fields: { source: { eq: "news" }, slug: { ne: null } }
            frontmatter: { type: { ne: null, eq: "Podcast" }, Published: { eq: true } }
          }
          sort: { fields: [fields___date, fields___slug, fields___locale], order: DESC }
          limit: 1
        ) {
          nodes {
            fields {
              socialImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        eventImage: allMdx(
          filter: {
            fields: { source: { eq: "news" }, slug: { ne: null } }
            frontmatter: { type: { ne: null, eq: "Event" }, Published: { eq: true } }
          }
          sort: { fields: [fields___date, fields___slug, fields___locale], order: DESC }
          limit: 1
        ) {
          nodes {
            fields {
              socialImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    `,
  );
  const imageNodes: Record<string, GraphqlPost[]> = {
    articles: articleImageNodes,
    podcasts: podcastImageNodes,
    events: eventImageNodes,
  };
  const { previous, current, next } = typesHelper.usePreviousAndCurrentAndNextLinks();
  const images = {
    previous: getImage(imageNodes[previous][0].fields.socialImage ?? null),
    current: getImage(imageNodes[current][0].fields.socialImage ?? null),
    next: getImage(imageNodes[next][0].fields.socialImage ?? null),
  };

  return (
    <article className={bgColors.current} itemScope itemType="https://schema.org/BlogPosting">
      <link itemProp="publisher" href={`${siteUrl}#organization`} />
      <div className="container min-h-screen px-4 pt-16 lg:flex lg:px-6 lg:pb-6 lg:pt-24 xl:max-w-[1418px]">
        <div className="flex h-[66vh] flex-col justify-between lg:block lg:h-auto lg:w-full">
          <div className="flex flex-row items-center justify-around px-4 py-0 pt-3 sm:px-8 lg:justify-between lg:px-0">
            {Object.keys(typesHelper.types).map((type) => (
              <HearderLink
                key={typesHelper.types[type].path}
                name={t(typesHelper.types[type].name)}
                path={typesHelper.types[type].path}
                root={typesHelper.types[type].root}
              />
            ))}
          </div>
          <SharedCard
            title={post.title}
            linkTo={post.slug}
            externalUrl={post.externalUrl}
            language={post.locale}
            withMicroData
          >
            <div className="relative z-20 m-auto mt-10 -rotate-6 text-center lg:hidden">
              {images.current ? (
                <GatsbyImage
                  image={images.current}
                  title={post.title}
                  alt={post.title}
                  className="h-40 w-40 border border-white sm:h-60 sm:w-60 md:h-80 md:w-80"
                />
              ) : (
                <div
                  className={`inline-block h-40 w-40 border border-white align-top sm:h-60 sm:w-60 md:h-80 md:w-80 ${bgColors.current}`}
                ></div>
              )}
            </div>
            <div className="absolute left-1/2 z-10 -translate-x-1/2 -translate-y-full rotate-6 border border-white text-center lg:hidden">
              {images.next ? (
                <GatsbyImage
                  image={images.next}
                  alt=""
                  className="h-40 w-40 sm:h-60 sm:w-60 md:h-80 md:w-80"
                />
              ) : (
                <div className={`h-40 w-40 sm:h-60 sm:w-60 md:h-80 md:w-80 ${bgColors.next}`}></div>
              )}
            </div>
            <div className="absolute left-1/2 z-0 -translate-x-1/2 -translate-y-full border border-white text-center lg:hidden">
              {images.previous ? (
                <GatsbyImage
                  image={images.previous}
                  alt=""
                  className="h-40 w-40 sm:h-60 sm:w-60 md:h-80 md:w-80"
                />
              ) : (
                <div
                  className={`h-40 w-40 sm:h-60 sm:w-60 md:h-80 md:w-80 ${bgColors.previous}`}
                ></div>
              )}
            </div>
            <div className="mt-5 text-center lg:mt-14 lg:text-left">
              <span
                className={`mb-2 mr-2 inline-block px-4 py-2 text-xs font-medium lg:text-2xl ${typesHelper.useTagColor()}`}
              >
                {translateLabel('theme', t)(post.theme)}
              </span>
            </div>
            <div
              className="mt-2 text-center font-semibold lg:text-left lg:text-4xl xl:text-5xl"
              itemProp="name headline"
            >
              {post.title}
            </div>
            <div className="hidden lg:mt-6 lg:line-clamp-6" itemProp="abstract">
              {post.abstract}
            </div>
            <div className="hidden text-sm lg:mt-6 lg:flex lg:flex-wrap lg:justify-start lg:whitespace-nowrap xl:text-base">
              {informations.map((information) => (
                <div key={information.title} className="mr-12">
                  <div className={`font-semibold ${bgColors.current}`}>{information.title}</div>
                  <div className={`font-semibold ${bgColors.current} opacity-70`}>
                    {information.description}
                  </div>
                </div>
              ))}
            </div>
          </SharedCard>
        </div>
        <div className="w-full lg:flex lg:flex-col lg:items-center lg:justify-evenly">
          <SharedCard
            title={post.title}
            linkTo={post.slug}
            externalUrl={post.externalUrl}
            language={post.locale}
            className="hidden lg:block"
            withMicroData
          >
            <div className="relative z-20 -rotate-6 border border-white">
              {images.current ? (
                <GatsbyImage
                  image={images.current}
                  title={post.title}
                  alt={post.title}
                  className="h-[358px] w-[358px] xl:h-[460px] xl:w-[460px]"
                />
              ) : (
                <div
                  className={`h-[358px] w-[358px] xl:h-[460px] xl:w-[460px] ${bgColors.current}`}
                ></div>
              )}
            </div>
            <div className="absolute z-10 hidden -translate-y-full rotate-6 border border-white text-center lg:block">
              {images.next ? (
                <GatsbyImage
                  image={images.next}
                  alt=""
                  className="h-[358px] w-[358px] xl:h-[460px] xl:w-[460px]"
                />
              ) : (
                <div
                  className={`h-[358px] w-[358px] xl:h-[460px] xl:w-[460px] ${bgColors.next}`}
                ></div>
              )}
            </div>
            <div className="absolute z-0 hidden -translate-y-full border border-white text-center lg:block">
              {images.previous ? (
                <GatsbyImage
                  image={images.previous}
                  alt=""
                  className="h-[358px] w-[358px] xl:h-[460px] xl:w-[460px]"
                />
              ) : (
                <div
                  className={`h-[358px] w-[358px] xl:h-[460px] xl:w-[460px] ${bgColors.previous}`}
                ></div>
              )}
            </div>
          </SharedCard>
          <a
            href="#search"
            className="absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap px-4 py-3 lg:static lg:contents"
            rel="nofollow"
          >
            <span className="flex text-sm font-medium lg:justify-center lg:text-lg">
              <span className="m-auto lg:mx-3">
                {t(`render.button.seePosts.${typesHelper.useSeePosts()}`)}
              </span>
              <span
                className={`m-auto ml-3 lg:rounded-full lg:border lg:border-current lg:${typesHelper.useArrowColor()} lg:mx-3 lg:bg-opacity-20 lg:p-4`}
              >
                <SvgDoubleArrows className="lg:h-[20px] lg:w-[20px]" />
              </span>
            </span>
          </a>
        </div>
      </div>
    </article>
  );
};

export default NewsHeader;
