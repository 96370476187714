import { graphql } from 'gatsby';
import NewsHome from '../components/news/news-home';

export default NewsHome;

// SYNC-235ODO993XCEDZOEF023
// Any modifications in this graphql should be implemented also in this SYNC number
export const query = graphql`
  query ($type: String!, $postsPublishedFilter: [Boolean]) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          name
          url
        }
      }
    }
    allMdx(
      filter: {
        fields: { source: { eq: "news" }, slug: { ne: null } }
        frontmatter: { type: { eq: $type }, Published: { in: $postsPublishedFilter } }
      }
      sort: { fields: [fields___date, fields___slug, fields___locale], order: DESC }
      limit: 1000
    ) {
      nodes {
        timeToRead
        excerpt
        frontmatter {
          title
          abstract
          duration
          type
          tags
          theme
          externalUrl
        }
        fields {
          slug
          date
          locale
          socialImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 432, backgroundColor: "transparent")
            }
          }
        }
      }
    }
  }
`;
