import { useLocation } from '@reach/router';
import { useLocalization } from 'gatsby-theme-i18n';
import siteOptions from '../../../config/options';
import * as Colors from '../Colors';

const { newsKey } = siteOptions;

type Types = {
  [key: string]: {
    name: string;
    path: string;
    colors: {
      [key: string]: string;
    };
    previous: string;
    next: string;
    root?: boolean;
  };
};
export const types: Types = {
  articles: {
    name: 'type.articles',
    path: 'articles',
    colors: {
      background: Colors.WHITE_ON_BLACK,
      tag: Colors.BLACK_ON_WHITE,
      arrow: 'bg-white text-white',
    },
    previous: 'events',
    next: 'podcasts',
    root: true,
  },
  podcasts: {
    name: 'type.podcasts',
    path: 'podcasts',
    colors: {
      background: Colors.WHITE_ON_BLUE,
      tag: Colors.BLUE_ON_WHITE,
      arrow: 'bg-white text-white',
    },
    previous: 'articles',
    next: 'events',
  },
  events: {
    name: 'type.events',
    path: 'events',
    colors: {
      background: Colors.BLACK_ON_YELLOW,
      tag: Colors.YELLOW_ON_BLACK,
      arrow: 'bg-black text-black',
    },
    previous: 'podcasts',
    next: 'articles',
  },
};

const usePathname = () => useLocation().pathname;

const useIsRootLocation = (path: string) => {
  const locale = useLocalization().locale;
  return path === `/${locale}/${newsKey}/`;
};

const getTypeInUrl = (pathname: string) => {
  const paths = pathname.split('/').filter((v) => v.length > 0);
  return paths[paths.length - 1];
};

const useColor = (colorKey: string) => {
  const pathname = usePathname();
  if (useIsRootLocation(pathname)) {
    return types.articles.colors[colorKey];
  }
  return types[getTypeInUrl(pathname)].colors[colorKey];
};
export const useArrowColor = () => {
  return useColor('arrow');
};
export const useBackgroundColor = () => {
  return useColor('background');
};
export const useTagColor = () => {
  return useColor('tag');
};

const getPath = (path: string) => {
  return `/${newsKey}/${path}/`;
};

export const usePreviousAndCurrentAndNextLinks = (): Record<string, string> => {
  let previous;
  let current;
  let next;
  const pathname = usePathname();
  if (useIsRootLocation(pathname)) {
    previous = types.articles.previous;
    current = types.articles.path;
    next = types.articles.next;
  } else {
    previous = types[getTypeInUrl(pathname)].previous;
    current = types[getTypeInUrl(pathname)].path;
    next = types[getTypeInUrl(pathname)].next;
  }
  return { previous, current, next };
};

export const usePreviousAndCurrentAndNextBackgroundColor = (): Record<string, string> => {
  const links = usePreviousAndCurrentAndNextLinks();
  return {
    previous: types[links.previous].colors.background,
    current: types[links.current].colors.background,
    next: types[links.next].colors.background,
  };
};

export const useSeePosts = () => {
  const pathname = usePathname();
  if (useIsRootLocation(pathname)) {
    return types.articles.path;
  }
  return types[getTypeInUrl(pathname)].path;
};

export const useIsActivePath = (targetPath: string, root?: boolean) => {
  const pathname = usePathname();
  const isRootLocation = useIsRootLocation(pathname);
  if (root && isRootLocation) {
    return true;
  }
  return pathname.startsWith(targetPath.replace('/#', '/'));
};

export const useTargetPath = (path: string) => {
  const { defaultLang, prefixDefault, locale, localizedPath } = useLocalization();
  return localizedPath({
    defaultLang,
    prefixDefault,
    locale,
    path: getPath(path),
  });
};
