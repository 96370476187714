import * as React from 'react';
import { PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { graphql2post, SiteQueryProps, AllMdxPost, PageContextProps } from '../../utils/graphql';
import useLocalizedPosts from '../../utils/use-localized-posts';
import DefaultLayout from '../layouts/default-layout';
import NewsFooter from './news-footer';
import NewsHeader from './news-header';
import PostsList from './posts-list';
import * as typesHelper from './typesHelper';

type NewsQueryProps = {
  site: SiteQueryProps;
  allMdx: AllMdxPost;
};

type NewsProps = PageProps<NewsQueryProps, PageContextProps>;

const NewsHome: React.VoidFunctionComponent<NewsProps> = ({
  data: {
    site,
    allMdx: { nodes },
  },
  pageContext,
}) => {
  const { t } = useTranslation('news');
  const posts = useLocalizedPosts(nodes.filter((n) => !!n.frontmatter).map(graphql2post));
  const bgColor = typesHelper.useBackgroundColor();
  const siteUrl = site.siteMetadata.siteUrl;

  return (
    <DefaultLayout
      headTitle={t('head-title')}
      headDescription={t('head-description')}
      lang={pageContext.locale}
      theme={{ class: bgColor, pseudoClass: bgColor }}
      preserveNavbarSpace={false}
    >
      <NewsHeader post={posts[0]} siteUrl={siteUrl} pageContext={pageContext} t={t} />
      <div className="container mt-4 px-4 lg:mt-10 lg:px-6 xl:max-w-[1418px]">
        <PostsList posts={posts} siteUrl={siteUrl} pageContext={pageContext} />
      </div>
      <NewsFooter pageContext={pageContext} />
    </DefaultLayout>
  );
};

export default NewsHome;
